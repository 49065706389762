<template>
    <div
        :id="blokId(blok)"
        v-editable="blok"
        class="flex flex-col lg:flex-row justify-between"
        :class="{ 'lg:flex-row-reverse': blok.reverse }"
    >
        <div
            class="mt-4 lg:mt-0 lg:w-1/2 px-4 sm:px-6 lg:px-8 flex items-center"
        >
            <div class="flex flex-col space-y-2 sm:space-y-4">
                <div v-if="blok.title" class="h1">
                    {{ blok.title }}
                </div>
                <div v-if="blok.subtitle" class="h3 -mt-3">
                    {{ blok.subtitle }}
                </div>
                <div
                    v-if="richContentText"
                    class="prose"
                    v-html="richContentText"
                />
                <ButtonContainer :buttons="blok.buttons" />
            </div>
        </div>
        <div class="mt-4 lg:mt-0 lg:w-1/2 px-4 sm:px-6 lg:px-0">
            <div class="aspect-video bg-gray-400">
                <video
                    v-if="blok.video_url"
                    :title="blok.title"
                    autoplay="autoplay"
                    loop="loop"
                    muted=""
                    playsinline=""
                    oncontextmenu="return false;"
                    preload="auto"
                    width="100%"
                    height="auto"
                    class="aspect-video"
                >
                    <source :src="blok.video_url" type="video/mp4" />
                </video>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { richTextResolver } from '@storyblok/richtext'
import ButtonContainer from '~/components/Buttons/ButtonContainer.vue'
import { blokId } from '~/utils/functions'

const { render } = richTextResolver()

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
})

const richContentText = computed(() => render(props.blok.content))
</script>
